import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import { getdepositlist, postRequest } from 'backendServices/ApiCalls';
import { Chip,IconButton, Tooltip, Grid, CircularProgress } from '@mui/material';
import { FileCopy as FileCopyIcon, CheckCircle as CheckCircleIcon, ArrowRightAlt } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ObjectCountRevenue } from 'app/shared/metrics/ObjectCountCards';
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import Div from '@jumbo/shared/Div';

const VISIBLE_FIELDS = ['sr', 'amount',  'status','payoutmethod','payoutaccount2','payoutaccount3','rejectreason', 'createdat'];

const PayoutReports = () => {

  const {t} = useTranslation();
  const [copiedRows, setCopiedRows] = useState([]);
  const [copiedRows2, setCopiedRows2] = useState([]);
  const [copiedRows3, setCopiedRows3] = useState([]);

  const [usersData, setUsersData] = useState([]);
  const [loading,setLoading]=useState(true)

  let params = {
    status: 'all',
    type:'payout',
    usertype:'receiver'
  };

  const UsersData = () => {
    postRequest(
      '/selecttransactions',
      params,
      (response) => {
        if (response?.data?.status === 'success') {
          setUsersData(response?.data);
        }
        setLoading(false)
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    UsersData();
  }, []);

  const generateRowId = () => {
    return `row-${Math.random().toString(36).substr(2, 9)}`;
  };



  const rows = usersData?.data || [];

  let idCounter = 1;

  const rowsWithId = rows.map((row) => ({
    ...row,
    id: idCounter++,
  }));

  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
        color: '#8cda24',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },
    },
  };


  if(loading){
    return  <Div
    sx={{
      display: "flex",
      minWidth: 0,
      alignItems: "center",
      alignContent: "center",
      height: "100%",
      justifyContent: "center", // Align items horizontally
      textAlign: 'center'
    }}
  >
    <img src={`${ASSET_IMAGES}/logo-loading.png`} style={{ width: '60px' }} className="rotating" />
  </Div>
  }


  return (

    <>
        <Grid container mb={5} spacing={3}>
          <Grid item xs={12} sm={4}>
            <ObjectCountRevenue
              value={`${(usersData?.history[0]?.week_total || 0).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}`}
              title={t("pages.title.lastweek")}
              color="primary.main"
              icon={<img src={`${ASSET_IMAGES}/web3/wallet.png`} style={{height:'70px'}} className="animate-scale" />}
              vertical={true}
              bgcolor='#111224b5'
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ObjectCountRevenue
              value={`${(usersData?.history[0]?.month_total || 0).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}`}
              title={t("pages.title.thismonth")}
              color="info.main"
              icon={<img src={`${ASSET_IMAGES}/web3/wallet.png`} style={{height:'70px'}}  className="animate-scale" />}
              vertical={true}
              bgcolor='#111224b5'

            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ObjectCountRevenue
              value={`${(usersData?.history[0]?.year_total || 0).toLocaleString('en-US', {style: 'currency',currency: 'USD',})}`}
              title={t("pages.title.thisyear")}
              color="success.main"
              icon={<img src={`${ASSET_IMAGES}/web3/wallet.png`} style={{height:'70px'}}  className="animate-scale" />}
              vertical={true}
              bgcolor='#111224b5'

            />
          </Grid>

        </Grid>

        <JumboDemoCard title={t("pages.title.PayoutSummary")} wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}>

<Box sx={{ height: 400, width: 1 }}>
  <DataGrid
    initialState={{
      initialState: {
        columns: {
          columnVisibilityModel: {
            id: false,
            avatar: false,
            website: false,
            email: false,
            phone: false,
            username: false,
            city: false,
            company: false,
            position: false,
            lastUpdated: false,
            salary: false,
          },
        },
      },
      pagination: { paginationModel: { pageSize: 24 } },
    }}
    rows={rowsWithId}
    getRowId={(row) => generateRowId()}
    columns={[
      ...VISIBLE_FIELDS.map((field) => {
        if (field === 'sr') {
          return {
            field,
            headerName: `${t("pages.title.Sr")}`,
            width: 50,
            renderCell: (params) => <strong>{params.row.id}</strong>,
          };
        } else if (field === 'amount') {
          return {
            field,
            headerName: `${t("pages.title.Amount")}`,
            width: 150,
            renderCell: (params) => {
              return params.row.payoutmethod === "Matic" ? params.value + ' Matic' : '$' + params.value;
            }
          };
        }else if (field === 'payoutmethod') {
          return {
            field,
            headerName: `${t("pages.title.payoutmethod")}`,
            width: 150,
            renderCell: (params) => {
              
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                
                  {
                    params.value === "Matic" ? 
                    (
                      <img src={`${ASSET_IMAGES}/maticicon.png`} style={{ width: '28px' }}  />

                    )
                    :
                    (
                      <img src={`${ASSET_IMAGES}/dollaricon.png`} style={{ width: '25px' }}  />

                    )
                  }
                  <ArrowRightAlt  />
                  <img src={`${ASSET_IMAGES}/maticicon.png`} style={{ width: '28px' }}  />
                  
                </div>
              )
            },
          };
        }else if (field === 'payoutaccount2') {
          return {
            field,
            headerName: 'Payout Address',
            width: 150,
            renderCell: (params) => {
              const isCopied3 = copiedRows3.includes(params.row.id);
              
              const handleCopyClick = () => {
                navigator.clipboard.writeText(params.value)
                  .then(() => {
                    setCopiedRows3((prevCopiedRows3) => [...prevCopiedRows3, params.row.id]);
                  })
                  .catch((error) => {
                    console.error('Copy failed:', error);
                  });
              };
  
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {isCopied3 ? (
                    <CheckCircleIcon style={{ marginRight: '4px', color: 'green' }} />
                  ) : (
                    <Tooltip title="Copy Hash" placement="top">
                      <IconButton
                        onClick={handleCopyClick}
                        size="small"
                        style={{ marginRight: '4px' }}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <span>{params.value}</span>
                </div>
              );
            },
          };
        }
        else if (field === 'payoutaccount3') {
          return {
            field,
            headerName: 'Trx Id',
            width: 150,
            renderCell: (params) => {
              const isCopied2 = copiedRows2.includes(params.row.id);
              
              const handleCopyClick = () => {
                navigator.clipboard.writeText(params.value)
                  .then(() => {
                    setCopiedRows2((prevCopiedRows2) => [...prevCopiedRows2, params.row.id]);
                  })
                  .catch((error) => {
                    console.error('Copy failed:', error);
                  });
              };
    
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {isCopied2 ? (
                          <CheckCircleIcon style={{ marginRight: '4px', color: 'green' }} />
                        ) : params.value === null ? '--' : (
                          <Tooltip title="Copy Hash" placement="top">
                            <IconButton
                              onClick={handleCopyClick}
                              size="small"
                              style={{ marginRight: '4px' }}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        )
                        }
                  <span>{params.value}</span>
                </div>
              );
            },
          };
        } else if (field === 'rejectreason') {
          return {
            field,
            headerName:  `${t("pages.title.Reason")}`,
            width: 150,
            renderCell: (params) => {
              const isCopied = copiedRows.includes(params.row.id);
              
              const handleCopyClick = () => {
                navigator.clipboard.writeText(params.value)
                  .then(() => {
                    setCopiedRows((prevCopiedRows) => [...prevCopiedRows, params.row.id]);
                  })
                  .catch((error) => {
                    console.error('Copy failed:', error);
                  });
              };
    
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {isCopied ? (
                    <CheckCircleIcon style={{ marginRight: '4px', color: 'green' }} />
                  ) : (
                    params.value === "" || params.value === null ? '' : (
                    <Tooltip title={t("pages.title.CopyDetails")} placement="top">
                      <IconButton
                        onClick={handleCopyClick}
                        size="small"
                        style={{ marginRight: '4px' }}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>)
                  )}
                  <span>{params.value}</span>
                </div>
              );
            },
          };
        } else if (field === 'status') {
          return {
            field,
            headerName: `${t("pages.title.Status")}`,
            width: 150,
            renderCell: (params) => (
              <Chip
                label={params.value}
                color={params.value === 'approved' ? 'success' 
                : params.value === 'rejected' ? 'error'
                : params.value === 'pending' ? 'warning'
                :
                'success'  
                }
                size="small"
              />
            ),
          };
        }
        
        // else if (field === 'details') {
        //   return {
        //     field,
        //     headerName: `${t("pages.title.Details")}`,
        //     width: 350,
        //     renderCell: (params) => {
        //       const isCopied = copiedRows.includes(params.row.id);
              
        //       const handleCopyClick = () => {
        //         navigator.clipboard.writeText(params.value)
        //           .then(() => {
        //             setCopiedRows((prevCopiedRows) => [...prevCopiedRows, params.row.id]);
        //           })
        //           .catch((error) => {
        //             console.error('Copy failed:', error);
        //           });
        //       };
    
        //       return (
        //         <div style={{ display: 'flex', alignItems: 'center' }}>
        //           {isCopied ? (
        //             <CheckCircleIcon style={{ marginRight: '4px', color: 'green' }} />
        //           ) : (
        //             <Tooltip title={t("pages.title.CopyDetails")} placement="top">
        //               <IconButton
        //                 onClick={handleCopyClick}
        //                 size="small"
        //                 style={{ marginRight: '4px' }}
        //               >
        //                 <FileCopyIcon />
        //               </IconButton>
        //             </Tooltip>
        //           )}
        //           <span>{params.value}</span>
        //         </div>
        //       );
        //     },
        //   };
        // } 
        
        else if (field === 'createdat') {
          return {
            field,
            headerName: `${t("pages.title.Date")}`,  
            width: 200,
            renderCell:(params) => {
              const dateObject = new Date(params.row.createdat);
            // Extract the year, month, and day components
            const year = dateObject.getFullYear();
            const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
            const day = dateObject.getDate().toString().padStart(2, '0');
            // Create a formatted date string (e.g., "YYYY-MM-DD")
            const formattedDate = `${month}/${day}/${year}`;
            return formattedDate
            }
          };
        }

        return {
          field,
          headerName: field,
          width: 150,
        };;
      }),
      
    ]}
    slots={{ toolbar: GridToolbar }}
    sx={gridDesign}
    pageSizeOptions={[24, 48, 96]}
    columnResize={true} // Enable column resizing
    slotProps={{
      toolbar: {
        showQuickFilter: true,
        quickFilterProps: { debounceMs: 500 },
      },
    }}
  />
</Box>
</JumboDemoCard>
    </>

  );
};

export default PayoutReports;
