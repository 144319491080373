import React from 'react';
import {Chip, ListItem, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import Span from "@jumbo/shared/Span";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { format } from 'date-fns';


const ArticleItem = ({article}) => {
    return (
        <ListItem alignItems='flex-start' sx={{p: theme => theme.spacing(1, 3)}}>
            <ListItemAvatar sx={{mr: 2, overflow: "hidden", borderRadius: 2}}>
                <img width={"140"} height={"105"} style={{verticalAlign: 'middle'}} alt={article.title}
                     src={`https://elevated.threearrowstech.com/uploads/news/${article.image}`}/>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Typography component={"div"} mt={1}>
                        <Chip label={'Admin'} sx={{mb: 1.25}} color='primary' size={'small'}/>
                        <Typography variant="h5" sx={{lineHeight: 1.5}}>{article.title}</Typography>
                        <Typography variant="body" sx={{lineHeight: 1.5}}>
                        <div dangerouslySetInnerHTML={{ __html: article.description }} />

                        </Typography>
                    </Typography>
                }
                secondary={
                    <Typography component={"div"}>
                        <Stack
                            direction="row"
                            divider={
                                <Divider orientation="vertical" flexItem sx={{height: 13, alignSelf: 'center'}}/>
                            }
                            spacing={1}
                        >
                            <Span sx={{color: 'text.secondary', fontSize: 12,mt:2}}>
                            {format(new Date(article.updated_at || '2024-01-24T09:07:27.000Z'), 'MM/dd/yyyy')}

                            </Span>
                        </Stack>
                    </Typography>
                }
            />
        </ListItem>
    );
};
/* Todo article prop define */
export default ArticleItem;
