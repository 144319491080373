import React from "react";
import List from "@mui/material/List";
import {
  Grid
} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useState } from "react";
import { useContext } from "react";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { updateProfileData } from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  username: yup.string("Enter username").required("username is required"),
  firstname: yup.string("Enter first name").required("first name is required"),
  lastname: yup.string("Enter last name").required("last name is required"),
  email: yup
    .string("Enter email address")
    .email("Invalid email address")
    .required("Email is required"),
  mobile: yup.string("Enter mobile number").required("Mobile is required"),
  mailingaddress: yup
    .string("Enter mailing address")
    .required("Address is required")
});

const About = () => {
  const { t } = useTranslation();
  const [country, setCountry] = useState("");
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  let userData = loginUserData;
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const onSubmitForm = (data, setSubmitting) => {
    let params = {
      username: data.username,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      mobile: data.mobile,
      address: data.mailingaddress,
      country: country,
      zipcode: data.zipcode,
      city: data.city,
      state: data.state,
      referral_side: data.referral_side,
    };
    updateProfileData(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          if(response?.data?.message === "username_already_existed")
          {
            setalertData({
              show: true,
              message: `${t("pages.title.usernametaken")}`,
              variant: "error",
            });
          }else{
            setalertData({
              show: true,
              message: `${t("pages.title.profiledatanotupdated")}`,
              variant: "error",
            });
          }

          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: `${t("pages.title.ProfileDataupdated")}`,
            variant: "success",
          });
          setSubmitting(false);
          setloginUserData((prevState) => ({
            ...prevState,
            country: country,
            email: data.email,
            mobile: data.mobile,
            firstname: data.firstname,
            lastname: data.lastname,
          }));
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  if (userData.firstname === "") {
    return <div>laoding</div>;
  }


  return (
    <JumboCardQuick title={t("pages.title.UpdateProfile")} sx={{overflow:'unset'}}>

        {alertData.show && (
          <SweetAlert alertData={alertData} setalertData={setalertData} />
        )}
      <List disablePadding>
        <Formik
          validateOnChange={true}
          enableReinitialize="true"
          initialValues={{
            username: userData.username || "",
            firstname: userData.firstname || "",
            lastname: userData.lastname || "",
            email: userData.email || "",
            mailingaddress: userData.address || "",
            mobile: userData.mobile || "",
            country: userData.country || "",
            zipcode: userData.zipcode || "",
            city: userData.city || "",
            state: userData.state || "",
            referral_side:userData.referral_side || ""
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            onSubmitForm(data, setSubmitting);
          }}
        >
          {({ isSubmitting, values, setFieldValue }) => (
            <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
              <Grid container spacing={2}>
              <Grid item xs={12}>
                  <JumboTextField
                    fullWidth
                    name="username"
                    label={t("pages.title.Username")}
                    type="text"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <JumboTextField
                    fullWidth
                    name="firstname"
                    label={t("pages.title.FirstName")}
                    type="text"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <JumboTextField
                    fullWidth
                    label={t("pages.title.LastName")}
                    name="lastname"
                    type="text"
                  />
                </Grid>

                <Grid item sm={6} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label={t("pages.title.Email")}
                    name="email"
                    type="email"
                  />
                </Grid>

                <Grid item sm={6} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label={t("pages.title.Mobile")}
                    name="mobile"
                    type="tel"
                  />
                </Grid>

                <Grid item sm={12} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label={t("pages.title.MailingAddress")}
                    name="mailingaddress"
                    type="text"
                  />
                </Grid>

                <Grid
                  item
                  sm={6}
                >
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    {t("pages.title.Submit")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </List>
    </JumboCardQuick>
  );
};

export default About;
